/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*

Global Styles

*/

:root {
  --main-bg-color: #fff;
  --header-bg-color: #00ad9f;
  --main-tx-color: #0b142e;
  --secondary-tx-color: #526ead;
  --submit-bg-color: #00ad9f;
  --copy-bg-color: #ff1854;
  --sec-btn-color: #146396;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body,
html {
  font-family: 'Roboto', sans-serif;
  font-size: 62.5%;
  line-height: 1.6;
  background-color: var(--main-bg-color);
}

h1 {
  font-size: 5rem;
  font-weight: bold;
  text-align: center;
}

h2 {
  color: var(--secondary-tx-color);
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  padding: 30px 0;
}

p {
  color: var(--main-tx-color);
  font-size: 2rem;
  text-align: center;
}

a {
  text-decoration: none;
}

/*

forms

*/

.container-pass {
  margin: 0 auto;
  width: 96%;
}

.password-form {
  display: flex;
  justify-content: center;
}

.password-label {
  font-size: 2.2rem;
  margin-right: 10px;
}

#password-length {
  font-size: 2rem;
  width: 60px;
  padding: 5px 0 5px 10px;
  border-radius: 4px;
  background-color: var(--submit-bg-color);
  border: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.password-copy {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
  height: 120px;
}

.password-field {
  color: var(--secondary-tx-color);
  font-size: 2rem;
  font-weight: bold;
  padding-bottom: 12px;
}

.btn-pass {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.password-btn {
  width: 120px;
  border-radius: 4px;
  font-size: 1.8rem;
  font-weight: bold;
  padding: 8px 20px;
  margin-left: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border: 0;
}

.copy-btn {
  background-color: var(--copy-bg-color);
  color: var(--main-bg-color);
  margin: 0;
}

.generator-btn {
  background-color: var(--sec-btn-color);
  color: var(--main-bg-color);
}

.reset-btn {
  background-color: var(--header-bg-color);
  color: var(--main-bg-color);
}

/*
  Media Queries
*/

@media (min-width: 1024px) {
  .container-form {
    width: 70%;
  }
}

@media (max-width: 500px) {
  .password-txt p {
    font-size: 1.8rem;
    padding-bottom: 20px;
  }
}

@media (max-width: 360px) {
  .password-field {
    font-size: 1.8rem;
  }
}
