/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*

Global Styles

*/

:root {
  --main-bg-color: #fff;
  --header-bg-color: #00ad9f;
  --main-tx-color: #0b142e;
  --secondary-tx-color: #526ead;
  --submit-bg-color: #00ad9f;
  --copy-bg-color: #ff1854;
  --sec-btn-color: #146396;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body,
html {
  font-family: 'Roboto', sans-serif;
  font-size: 62.5%;
  line-height: 1.6;
  background-color: var(--main-bg-color);
}

h1 {
  font-size: 5rem;
  font-weight: bold;
  text-align: center;
}

h2 {
  color: var(--secondary-tx-color);
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  padding: 30px 0;
}

p {
  color: var(--main-tx-color);
  font-size: 2rem;
  text-align: center;
}

a {
  text-decoration: none;
}

/*

forms

*/

.container-numbers {
  margin: 0 auto;
  width: 96%;
}

.list-output {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.label {
  font-size: 2rem;
  margin-right: 10px;
}

.list-input {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.input {
  font-size: 1.8rem;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid var(--main-tx-color);
  width: 160px;
}

.btn-numbers {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}

.numbers-btn {
  width: 120px;
  border-radius: 4px;
  font-size: 1.8rem;
  font-weight: bold;
  padding: 8px 20px;
  margin-left: 2px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border: 0;
}

.input-btn {
  background-color: var(--submit-bg-color);
  color: var(--main-bg-color);
  margin-right: 5px;
}

.output-btn {
  background-color: var(--copy-bg-color);
  color: var(--main-bg-color);
  margin-left: 5px;
}
