/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*

Global Styles

*/

:root {
  --main-bg-color: #fff;
  --header-bg-color: #00ad9f;
  --main-tx-color: #0b142e;
  --secondary-tx-color: #526ead;
  --submit-bg-color: #00ad9f;
  --copy-bg-color: #ff1854;
  --sec-btn-color: #146396;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body,
html {
  font-family: 'Roboto', sans-serif;
  font-size: 62.5%;
  line-height: 1.6;
  background-color: var(--main-bg-color);
}

h1 {
  font-size: 5rem;
  font-weight: bold;
  text-align: center;
}

h2 {
  color: var(--secondary-tx-color);
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  padding: 30px 0;
}

p {
  color: var(--main-tx-color);
  font-size: 2rem;
  text-align: center;
}

a {
  text-decoration: none;
}

/*

Utilities

*/

.container {
  max-width: 1200px;
  margin: 0 auto;
  overflow: auto;
  padding: 0 20px;
}

/*

Navbar

*/

.navbar {
  background-color: var(--header-bg-color);
  color: var(--main-bg-color);
}

.flex-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
}

.navbar ul {
  display: flex;
  font-size: 2.2rem;
  font-weight: bold;
}

.navbar ul li a {
  padding: 12px 12px;
  margin: 0 18px;
  text-align: center;
  color: var(--nav-tx-color);
}

.main-nav-active {
  font-weight: bold;
  border-bottom: 3px solid var(--main-bg-color);
}

/*

numbers page

*/

/* 

Media Queries

*/

@media (max-width: 1180px) {
  .navbar h1 {
    margin: 0 auto;
  }
  .flex-container {
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
  }
  .navbar ul {
    margin: 0 auto;
  }
}

@media (max-width: 1029px) {
  .flex-container {
    padding: 20px 0;
  }
  .navbar ul {
    padding-top: 20px;
  }
  .navbar ul li a {
    padding: 12px 10px;
  }
}

@media (max-width: 810px) {
  .navbar ul {
    font-size: 1.8rem;
  }
}

@media (max-width: 705px) {
  .navbar ul {
    font-size: 1.7rem;
  }
}

@media (max-width: 685px) {
  .navbar ul {
    font-size: 1.5rem;
  }
  .navbar ul li a {
    padding: 12px 8px;
  }
}

@media (max-width: 614px) {
  .navbar ul {
    font-size: 1.4rem;
  }
  .navbar ul li a {
    padding: 12px 5px;
  }
}

@media (max-width: 570px) {
  .flex-container {
    padding: 20px 0;
  }
  .navbar ul li a {
    padding: 0;
  }
}

@media (max-width: 530px) {
  .navbar ul {
    font-size: 1.4rem;
    margin: 0;
    text-align: center;
  }
}

@media (max-width: 510px) {
  .flex-container {
    display: flex;
    flex-direction: column;
  }
  .navbar ul {
    font-size: 2rem;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
  }
  .navbar ul li {
    padding-bottom: 10px;
  }
}
